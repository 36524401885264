import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';
import {graphql} from 'gatsby';
import marked from 'marked';

import components, {Layout, SecondaryHeroSection} from '../components/index';
import {classNames, withPrefix, htmlToReact} from '../utils';
import BlogPostCategories from '../components/BlogPostCategories';
import BlogPostAuthor from '../components/BlogPostAuthor';
import BlogPostTags from '../components/BlogPostTags';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Post extends React.Component {
    render() {
        let has_image = false;
        let image_pos = _.get(this.props, 'pageContext.frontmatter.image_position', null) || 'top';
        if (_.get(this.props, 'pageContext.frontmatter.image', null)) {
             has_image = true;
        }
		let portfolioHeader = {
			section: {
				type: "secondary_hero_section",
				// title: "Blog",
				subtitle: "Voz + innovación",
				align: "center",
				padding_top: "medium",
				padding_bottom: "small",
				background_color: "cereza"
			}
		}

		let moreBlogSections = [{
				type: "blog_feed_section",
				// title: "Escribimos posts sobre innovación en la voz",
				subtitle: "Otros posts que hemos escrito",
				actions: [
					{
						label: "Todas las entradas",
						url: "/blog",
						style: "transparent-blanco"
					}
				],
					
				blog_feed_cols: "three",
				enable_cards: true,
				show_recent: true,
				recent_count: 3,
				show_image: true,
				show_date: true,
				show_categories: true,
				show_author: false,
				show_excerpt: true,
				align: "center",
				padding_top: "large",
				padding_bottom: "large",
				has_border: false,
				background_color: "morado"
			},
			{
				type: 'form_section',
				subtitle: "¿Hablamos?",
				title_align: 'center',
				title: ' ',
				content: marked(`### ¿Tienes una idea o proyecto en mente? Trabajemos juntos y hagamos algo memorable. ¡Escríbenos! \n\r#### Encuéntranos en \n\r<div class="feature__subtitle">Impact Hub Alameda</div>\n\rC/ Alameda, 22. 28014 Madrid`),
				content_align: "left",
				form_position: "right",
				form_width: "fifty",
				form_layout: "inline",
				enable_card: false,
				form_id: 'contact-form',
				form_action: '/thank-you',
				form_fields: [{
					input_type: "text",
					name: "name",
					label: '',
					default_value: "Nombre y apellidos",
					is_required: true
				},
				{
					input_type: "email",
					name: "email",
					label: '',
					default_value: "Email",
					is_required: true
				},
				{
					input_type: "textarea",
					name: "message",
					label: '',
					default_value: "Mensaje"
				},
				{
					input_type: "checkbox",
					name: "consent",
					label: marked('Acepto la [política de privacidad](/terms-conditions)'),
					is_required: true
				}],
				submit_label: "Enviar",
				align_vert: "top",
				padding_top: "large",
				padding_bottom: "large",
				background_color: "primary",
				background_image: "images/monoceros/dialog_monotono_1400.jpg",
				background_image_position: "bottom center"
			}
		]
		
        return (
            <Layout {...this.props}>
			<SecondaryHeroSection {...portfolioHeader}/>
            <article className="post">
            	<div className={classNames('post__hero', 'container--post', {'container--medium': (image_pos === 'top') || (has_image === false)})}>
            		<div className={classNames('mb-4', {'mb-md-5': image_pos !== 'top', 'mb-md-6': image_pos !== 'top', '': image_pos !== 'top', 'items-center': has_image && (image_pos !== 'top')})}>
            			{has_image && (
            			<div className={classNames('post__image', 'mb-3', {'': image_pos !== 'top', '': image_pos !== 'top', 'mb-lg-0': image_pos !== 'top'})}>
            				<img src={withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null))} alt={_.get(this.props, 'pageContext.frontmatter.image_alt', null)} />
            			</div>
            			)}
            		</div>
            	</div>
            	<div className="container--post">
					<header className={classNames('post__header', {'': image_pos !== 'top', '': image_pos !== 'top', 'order-lg-first': has_image && (image_pos === 'right')})}>
            				<div className="post__meta mb-2">
            					{_.get(this.props, 'pageContext.frontmatter.categories', null) && (<React.Fragment>
            						<BlogPostCategories {...this.props} categories={_.get(this.props, 'pageContext.frontmatter.categories', null)} container_class={'post__cat'} />
            						{/* <span className="post__meta-sep"> &middot; </span> */}
            					</React.Fragment>)}
            					
            				</div>
            				<h1 className="post__title mt-0">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
            				{_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
            					<p className="post__subtitle">{_.get(this.props, 'pageContext.frontmatter.subtitle', null)}</p>
            				)}
							<div className="post__meta mb-2 post__author">
								{_.get(this.props, 'pageContext.frontmatter.author', null) && (
									<BlogPostAuthor {...this.props} author={_.get(this.props, 'pageContext.frontmatter.author', null)} container_class={'author'} avatar_size={'medium'} />
								)}
								<span className="post__date"><time dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date', null)).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(this.props, 'pageContext.frontmatter.date', null)).strftime('%d/%m/%Y')}</time></span>
							</div>
					</header>
            		<div className="post__body text-block">
            			{htmlToReact(_.get(this.props, 'pageContext.html', null))}
            		</div>
            		{_.get(this.props, 'pageContext.frontmatter.tags', null) && (
            		<footer className="post__footer mt-4 mt-md-5">
            			<BlogPostTags {...this.props} tags={_.get(this.props, 'pageContext.frontmatter.tags', null)} />
            		</footer>
            		)}
            	</div>
            </article>
			{_.map(moreBlogSections, (section, section_idx) => {
				console.log(section, section_idx)
                let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                let Component = components[component];
                return (
                  <Component key={section_idx} {...this.props} section={section} site={this.props.pageContext.site} />
                )
            })}
            </Layout>
        );
    }
}
